

    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'ClippingAnimation',
        props: {
            interactive: { type: Boolean, value: false }
        }
    })

