import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["custom-cursor", { '--dark': _ctx.isDark }])
    }, [
      _createElementVNode("div", {
        class: "custom-cursor__background",
        style: _normalizeStyle(_ctx.computedStyle.background)
      }, null, 4),
      (_ctx.text)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "custom-cursor__content --show-blend",
            style: _normalizeStyle(_ctx.computedStyle.content)
          }, _toDisplayString(_ctx.text), 5))
        : _createCommentVNode("", true)
    ], 2),
    _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({ eventListeners: _ctx.eventListeners, isHover: _ctx.isHover, shrink: _ctx.shrink })))
  ], 64))
}