

    import { computed, defineComponent, reactive } from 'vue'
    import { useCursorState } from '../compositions/UseCursorState'

    export default defineComponent({
        name: 'CustomCursor',
        props: {
            text: { type: String, default: null },
            isDark: { type: Boolean, default: false }
        },
        setup() {

            const {
                isHover, isDown, eventListeners,
                onMouseMove, onMouseEnter, onMouseLeave, onInteraction, onMouseDown, onMouseUp
            } = useCursorState()

            const style = reactive({ x: 0, y: 0, contentScale: 0, backgroundScale: 0, transitionDuration: 0 })

            const computedStyle = computed(() => ({
                content: {
                    transform: `translate3d(${ style.x }px, ${ style.y }px, 0) scale(${ style.contentScale })`
                },
                background: {
                    '--transition-duration': `${ style.transitionDuration }ms`,
                    transform: `translate3d(${ style.x }px, ${ style.y }px, 0) scale(${ style.backgroundScale })`
                }
            }))

            function shrink() {

                style.backgroundScale = 0
                style.contentScale = 0

            }

            onMouseEnter(() => {

                shrink()
                style.transitionDuration = 0

                setTimeout(() => {

                    style.transitionDuration = 300

                })

            })

            onMouseLeave(shrink)

            onMouseDown(() => {

                style.backgroundScale = 0.77
                style.contentScale = 1

            })

            onMouseUp(() => {

                style.backgroundScale = 1
                style.contentScale = 1

            })

            /**
             * This is triggered on any event
             */
            onInteraction(event => {

                if (event instanceof MouseEvent) {

                    style.x = event.x - 50
                    style.y = event.y - 50

                }

            })

            onMouseMove(() => {

                setTimeout(() => {

                    const contentScale = isHover.value ? 1 : 0
                    const backgroundScale = isDown.value ? 0.77 : contentScale

                    style.backgroundScale = backgroundScale
                    style.contentScale = contentScale

                })

            })

            return {
                isHover,
                eventListeners,
                computedStyle,
                shrink
            }

        }
    })

