import { ref } from 'vue'
import { currentActiveBreakPoint } from './UseResponsiveValue'
import { Breakpoint } from '../enums/Breakpoint'

type DestroyListener = () => void

export function useCursorState() {

    const isHover = ref(false)
    const isDown = ref(false)

    const listeners: Record<string, ((event: Event | MouseEvent | PointerEvent) => void)[]> = {
        up: [],
        down: [],
        move: [],
        enter: [],
        leave: [],
        interaction: []
    }

    function registerListener(name: string, listener: (event: Event) => void): DestroyListener {

        listeners[name].push(listener)

        return function() {

            const index = listeners[name].findIndex(item => item === listener)

            listeners[name].splice(index, 1)

        }

    }

    function onMouseMove(listener: (event: MouseEvent) => void): DestroyListener {

        return registerListener('move', listener as (event: Event) => void)

    }

    function onMouseLeave(listener: (event: MouseEvent) => void): DestroyListener {

        return registerListener('leave', listener as (event: Event) => void)

    }

    function onMouseEnter(listener: (event: MouseEvent) => void): DestroyListener {

        return registerListener('enter', listener as (event: Event) => void)

    }

    function onMouseDown(listener: (event: MouseEvent) => void): DestroyListener {

        return registerListener('down', listener as (event: Event) => void)

    }

    function onMouseUp(listener: (event: MouseEvent) => void): DestroyListener {

        return registerListener('up', listener as (event: Event) => void)

    }

    function onInteraction(listener: (event: MouseEvent) => void): DestroyListener {

        return registerListener('interaction', listener as (event: Event) => void)

    }

    function runMouseEvent(event: Event) {

        /**
         * Disable all events on mobile devices
         */
        if (currentActiveBreakPoint.value === Breakpoint.SM) {

            return

        }

        if ([ 'mousedown', 'mouseup', 'pointerup', 'pointerdown' ].includes(event.type)) {

            isDown.value = [ 'mousedown', 'pointerdown' ].includes(event.type)

            if (isDown.value) {

                for (const listener of listeners.down) {

                    listener(event)

                }

            } else {

                for (const listener of listeners.up) {

                    listener(event)

                }

            }

        }

        if ([ 'mouseenter', 'mousemove', 'pointermove' ].includes(event.type)) {

            isHover.value = true

            if (event.type === 'mouseenter') {

                for (const listener of listeners.enter) {

                    listener(event)

                }

            }

            if (event.type === 'mousemove' || event.type === 'pointermove') {

                for (const listener of listeners.move) {

                    listener(event)

                }

            }

        }

        if ([ 'mouseleave' ].includes(event.type)) {

            isHover.value = false
            isDown.value = false

            for (const listener of listeners.leave) {

                listener(event)

            }

        }

        for (const listener of listeners.interaction) {

            listener(event)

        }

    }

    return {
        isHover,
        isDown,
        onMouseMove,
        onMouseLeave,
        onMouseEnter,
        onMouseDown,
        onMouseUp,
        onInteraction,
        eventListeners: {
            onMouseup: runMouseEvent,
            onMousedown: runMouseEvent,
            onMouseleave: runMouseEvent,
            onMouseenter: runMouseEvent,
            onMousemove: runMouseEvent,
            onPointermove: runMouseEvent,
            onPointerdown: runMouseEvent,
            onPointerup: runMouseEvent
        }
    }

}
